/* eslint-disable max-len, import/no-cycle */
import { reactive, toRefs } from '@vue/composition-api';
import router from '@/router';

const state = reactive({
    isConnected: true,
});

export default function useConnection() {
    /**
     * Method to check if BWS is reachable
     * @returns {Boolean}
     */
    const isReachable = () => fetch(process.env.VUE_APP_BACKEND_URL, { method: 'HEAD', mode: 'no-cors' })
        .then((response) => response && (response.ok || response.type === 'opaque'))
        .catch((error) => console.warn('[conn test failure]:', error));

    /**
     * Method to handle connection event
     *
     * @returns {void}
     */
    const handleConnection = () => {
        if (navigator.onLine) {
            isReachable().then((online) => {
                state.isConnected = !!online;
            });
        } else {
            state.isConnected = false;
        }

        if (!state.isConnected && router.currentRoute.name === 'NewMachine') {
            router.push({ name: 'ConnectionError' });
        }

        if (state.isConnected && router.currentRoute.name === 'ConnectionError') {
            router.push({ name: 'HomeScreen' });
        }
    };

    return {
        ...toRefs(state),
        handleConnection,
    };
}
